import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  DxcDropdown,
  DxcButton,
  DxcLink,
  DxcApplicationLayout,
  DxcHeading,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";
import { logOut, isLoggedIn } from "../services/login.js";
import SessionContext from "../SessionContext.js";
import userIcon from "../images/user_login.svg";
import link from "../common/images/open_in_new.svg";

const Header = ({ handleLogin, handleRegister }) => {
  const history = useHistory();
  const location = useLocation();
  const session = useContext(SessionContext);

  const optionsDesign = [
    {
      value: 1,
      label: "Overview",
    },
    {
      value: 2,
      label: "Tools",
    },
    {
      value: 3,
      label: "Help",
    },
  ];

  const optionsAPIs = [
    {
      value: 1,
      label: "Guidelines",
    },
    {
      value: 2,
      label: "API Catalog",
    },
    {
      value: 3,
      label: "API Status",
    },
    {
      value: 4,
      label: "Tools",
    },
  ];

  const optionsUser = [
    {
      value: 1,
      label: "Your API Key",
    },
    {
      value: 2,
      label: "Sign out",
    },
  ];

  const navigateToHome = () => {
    if (history) {
      history.push("/");
    }
  };

  const selectDesignOption = (selectedOption) => {
    if (history) {
      selectedOption === 1
        ? history.push("/design")
        : selectedOption === 2
        ? history.push("/design/tools")
        : history.push("/design/help");
    }
  };

  const selectAPIsOption = (selectedOption) => {
    if (history) {
      selectedOption === 1
        ? history.push("/apis")
        : selectedOption === 2
        ? history.push("/apis/catalog")
        : selectedOption === 3
        ? history.push("/apis/status")
        : history.push("/apis/tools");
    }
  };

  const selectUserOption = (selectedOption) => {
    if (history) {
      selectedOption === 1 ? history.push("/dashboard") : signOut();
    }
  };

  const getUsername = () => {
    const username = session.username;
    return username && username.split("@") && username.split("@")[0];
  };

  const signOut = () => {
    location.pathname === "/dashboard" && navigateToHome();
    session.resetSession();
    logOut();
  };

  return (
    <DxcApplicationLayout.Header
      underlined={true}
      onClick={navigateToHome}
      content={
        <React.Fragment>
          <HeaderContentContainer>
            <HeaderTitleContainer>
              <HeaderTitle>DEVELOPER CENTRAL</HeaderTitle>
            </HeaderTitleContainer>
            <HeaderOptions>
              <DxcApplicationLayout.Header.Dropdown
                label="Design System"
                options={optionsDesign}
                onSelectOption={selectDesignOption}
                expandOnHover
              />
              <DxcApplicationLayout.Header.Dropdown
                label="APIs"
                options={optionsAPIs}
                onSelectOption={selectAPIsOption}
                expandOnHover
              />
              <LinkApplications>
                <a href="https://assure.dxc.com/marketplace">Applications</a>
              </LinkApplications>
            </HeaderOptions>
            <HeaderLogin>
              {!isLoggedIn() ? (
                <DxcFlex>
                  <Link to="#" onClick={handleLogin}>
                    Sign in
                  </Link>
                  <Separator>/</Separator>
                  <Link to="#" onClick={handleRegister}>
                    Register
                  </Link>
                </DxcFlex>
              ) : (
                <SignOutDashboard>
                  <DxcApplicationLayout.Header.Dropdown
                    label={getUsername()}
                    options={optionsUser}
                    onSelectOption={selectUserOption}
                    expandOnHover
                    icon={userIcon}
                    iconPosition="after"
                  />
                </SignOutDashboard>
              )}
            </HeaderLogin>
          </HeaderContentContainer>
        </React.Fragment>
      }
      responsiveContent={(closeHandler) => (
        <React.Fragment>
          <HeaderContainerResponsive>
            <DxcInset top="3rem">
              <DxcFlex direction="column">
                <DxcHeading level={4} text="Design System" />
                <ResponsiveList>
                  <ResponsiveListItem>
                    <Link to="/design" onClick={closeHandler}>
                      Overview
                    </Link>
                  </ResponsiveListItem>
                  <ResponsiveListItem>
                    <Link to="/design/tools" onClick={closeHandler}>
                      Tools
                    </Link>
                  </ResponsiveListItem>
                  <ResponsiveListItem>
                    <Link to="/design/help" onClick={closeHandler}>
                      Help
                    </Link>
                  </ResponsiveListItem>
                </ResponsiveList>
                <DxcHeading level={4} text="APIs" />
                <ResponsiveList>
                  <ResponsiveListItem>
                    <Link to="/apis" onClick={closeHandler}>
                      Guidelines
                    </Link>
                  </ResponsiveListItem>
                  <ResponsiveListItem>
                    <Link to="/apis/catalog" onClick={closeHandler}>
                      API Catalog
                    </Link>
                  </ResponsiveListItem>
                  <ResponsiveListItem>
                    <Link to="/apis/status" onClick={closeHandler}>
                      API Status
                    </Link>
                  </ResponsiveListItem>
                  <ResponsiveListItem>
                    <Link to="/apis/tools" onClick={closeHandler}>
                      Tools
                    </Link>
                  </ResponsiveListItem>
                </ResponsiveList>
                <DxcLink
                  href="https://assure.dxc.com/marketplace"
                  icon={link}
                  iconPosition="after"
                  inheritColor={true}
                >
                  Applications
                </DxcLink>
              </DxcFlex>
            </DxcInset>
            <DxcInset horizontal="1.5rem">
              {!isLoggedIn() ? (
                <DxcFlex gap="1.5rem" justifyContent="center">
                  <DxcButton
                    label="Sign in"
                    onClick={() => {
                      closeHandler();
                      handleLogin();
                    }}
                  />
                  <DxcButton
                    label="Register"
                    mode="secondary"
                    onClick={() => {
                      closeHandler();
                      handleRegister();
                    }}
                  />
                </DxcFlex>
              ) : (
                <SignOutDashboardResponsive>
                  <DxcDropdown
                    label={getUsername()}
                    options={optionsUser}
                    onSelectOption={selectUserOption}
                    icon={userIcon}
                    iconPosition="after"
                  />
                </SignOutDashboardResponsive>
              )}
            </DxcInset>
          </HeaderContainerResponsive>
        </React.Fragment>
      )}
    ></DxcApplicationLayout.Header>
  );
};

const HeaderContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainerResponsive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 48px);
  width: 100%;
`;

const ResponsiveList = styled.ul`
  padding-left: 20px;
  & + a {
    font-weight: 600;
  }
`;

const ResponsiveListItem = styled.li`
  list-style: none;
  margin-bottom: 12px;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  margin-left: 24px;
  padding-left: 24px;
  font-size: 13px;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: 2px;

  a {
    color: white;
    text-decoration: none;
  }
`;

const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;

  & > div {
    height: 100%;
  }

  & > div:last-child {
    padding: 0px 20px;
  }
`;

const LinkApplications = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & a {
    color: inherit;
    text-decoration: none;
    align-items: center;
  }
`;

const Separator = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

const HeaderLogin = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 57px;

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
`;

const SignOutDashboard = styled.div`
  width: 205px;
  height: 40px;
  & > div {
    height: 40px;
  }
`;

const SignOutDashboardResponsive = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  & > div {
    height: 40px;
  }
`;

export default Header;
