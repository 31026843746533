import React from "react";
import styled from "styled-components";
import { typeface } from "../../common/variables.js";
import { useScreenType } from "../../common/utils";
import { DxcLink } from "@dxc-technology/halstack-react";

const FAQ = () => {
  const screenType = useScreenType();
  return (
    <FAQContainer screenType={screenType}>
      <TitleContainer screenType={screenType}>
        Frequently Asked Questions
      </TitleContainer>
      <ContentSectionContainer>
        <SubTitleContainer screenType={screenType}>
          What is the difference between the Design System, CDK and Halstack?
        </SubTitleContainer>
        <TextContainer>
          The Design System introduced and documented through this portal
          summarizes how DXC designs and builds user experiences for digital
          products. It includes <DxcLink href="/design">principles</DxcLink> and{" "}
          <DxcLink href="/design/tools">tools</DxcLink> to help designers and
          developers in building visually-consistent user interfaces that adhere
          to our unique visual language. In our case, this Design System is
          allowing us to manage the UX design of our global digital products at
          scale thanks to the collection of reusable components.
        </TextContainer>
        <TextContainer>
          The Component Development Kit (CDK) provide engineers with a set of
          well-tested UI components for building software products. Available in
          React, this tool helps both product design and build teams in
          enforcing the UX guidelines established by the Design System
          transparently in their user interfaces.
        </TextContainer>
        <TextContainer>
          At DXC, we have open-sourced boh the Design System and the CDK under
          the{" "}
          <DxcLink href="https://dxc-technology.github.io/halstack/">
            Halstack
          </DxcLink>{" "}
          project name so it is now a community asset. Together with other
          API-related tools included in the Halstack family, product development
          teams are building incredibly reactive and performant UIs on top of
          hypermedia REST APIs.
        </TextContainer>
      </ContentSectionContainer>
      <ContentSectionContainer>
        <SubTitleContainer screenType={screenType}>
          Can I use the Halstack Design System without the CDK or apply it to a
          legacy UI?
        </SubTitleContainer>
        <TextContainer>
          Yes. At its core, Halstack Design System provides a set of UX design
          guidelines that can be used by designers and developers to implement
          their products using the technology they want, even legacy. It is true
          that using the Halstack CDK helps in enforcing those guidelines, but
          it is not mandatory. As long as those guidelines are transmitted to a
          final user interface by means of a compatible UX design, our proposed
          unique visual language will be preserved.
        </TextContainer>
      </ContentSectionContainer>
      <ContentSectionContainer>
        <SubTitleContainer screenType={screenType}>
          Where can I find Halstack Design System reference implementations?
        </SubTitleContainer>
        <TextContainer>
          This Developer Central site is 100% powered by Halstack. From the home
          page to the <DxcLink href="/apis/catalog">API Catalog</DxcLink>, it
          has been implemented using{" "}
          <DxcLink href="https://developer.dxc.com/halstack/">
            Halstack React CDK
          </DxcLink>
          . Moreover, the API catalog features other{" "}
          <DxcLink href="/apis/tools">Halstack API tools</DxcLink>, such as
          Halstack Client and Halstack Browser, aimed at helping developers with
          the consumption of hypermedia REST APIs. Apart from that, all DXC
          Assure applications for Insurance are now powered by Halstack,
          becoming and important stepping stone in our SaaS strategy. It is
          important to mention that Halstack Design System and CDKs allow
          product development teams to easily white-label their SaaS
          applications for alternative brandings by means of component themable
          properties.
        </TextContainer>
      </ContentSectionContainer>
      <ContentSectionContainer>
        <SubTitleContainer screenType={screenType}>
          What tools do I have as a designer/developer to work with Halstack
          Design System?
        </SubTitleContainer>
        <TextContainer>
          Halstack Design System provides{" "}
          <DxcLink href="/design/tools">
            tools for both designers and developers
          </DxcLink>
          . On one hand, the Design Kit is right now being migrated from Adobe
          XD to Figma. Figma will help designers with a predefined catalog of
          components to speed up design and preserve our proposed visual
          language. On the other hand, React Halstack CDK can be integrated
          easily with any SPA development using that Web framework, since the
          libraries have been published on NPM and are available to be included
          as dependencies in the development projects.
        </TextContainer>
      </ContentSectionContainer>
      <ContentSectionContainer>
        <SubTitleContainer screenType={screenType}>
          Will I automatically receive all the advantages described in this page
          if I use the Halstack CDKs?
        </SubTitleContainer>
        <TextContainer>
          Mostly, but let's be realistic: even with Halstack, designers and
          developers have a lot of freedom to build the UIs they want, in the
          way they want. Although Halstack Design System and CDKs provide rails
          for the product development teams to deliver visually-consistent and
          coherent applications, these are not no-code tools, and all the
          invidual judgements, pesonal tastes and programming decissions will
          still influence the final result tremendously. However, this risk of
          visual inconsistency is minimized with the guidelines and tools
          provided by Halstack.
        </TextContainer>
        <TextContainer>
          As an open source asset, we count on Halstack to be continuosly
          evolving thanks to the contributions of the global community. At DXC
          we are commited to make that happen, and as we move forward, new
          accessibility and layout guidelines will be factored in during the
          incoming months in order to guide developers in producing superior
          user experiences in the UIs of their digital products.
        </TextContainer>
      </ContentSectionContainer>
    </FAQContainer>
  );
};

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) =>
    props.screenType === "desktop"
      ? "5% 28% 5% 10%"
      : props.screenType === "tablet"
      ? "20px"
      : ""};
  min-height: 2000px;
  p {
    font-size: ${(props) =>
      props.screenType === "mobile" ? "14px" : "inherit"};
    line-height: 24px;
  }
`;

const ContentSectionContainer = styled.div`
  margin-top: ${(props) => (props.screenType !== "desktop" ? "30px" : "")};
`;

const TitleContainer = styled.div`
  text-align: left;
  font-size: ${(props) => (props.screenType === "desktop" ? "48px" : "32px")};
  margin-bottom: ${(props) => (props.screenType !== "desktop" ? "12px" : "")};
  letter-spacing: 0;
  color: #000000de;
  opacity: 1;
`;

const SubTitleContainer = styled.div`
  text-align: left;
  font-size: ${(props) => (props.screenType === "desktop" ? "34px" : "24px")};
  letter-spacing: 0.24px;
  color: #000000de;
  opacity: 1;
  margin: 0px 0px 10px 0px;
`;

const TextContainer = styled.div`
  text-align: left;
  font: Normal ${typeface.body.fontSize} Open Sans;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  padding: ${(props) => (props.screenType === "desktop" ? "3% 0" : "0")};
  margin-top: ${(props) => (props.screenType !== "desktop" ? "15px" : "")};
`;

export default FAQ;
